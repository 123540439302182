export default () => {
  let ready = false;

  apos.util.onReady(() => {
    if (!ready) {
      ready = true;
      apos.bus.$on('page-tree-qa-add', add);
      apos.bus.$on('page-tree-qa-remove', remove);
    }
  });

  async function add() {
    await apos.http.post('/api/v1/@apostrophecms/page/qa-add', {});
    window.location.reload();
  }

  async function remove() {
    await apos.http.post('/api/v1/@apostrophecms/page/qa-remove', {});
    window.location.reload();
  }
};
