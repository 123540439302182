<template>
  <svg viewBox="0 0 141.5 144.5">
    <linearGradient
      id="circle"
      gradientUnits="userSpaceOnUse"
      x1="125.627"
      y1="71.954"
      x2="94.317"
      y2="17.734"
      gradientTransform="matrix(1 0 0 -1 0 115.192)"
    >
      <stop
        offset="0"
        stop-color="#cc9300"
      />
      <stop
        offset=".47"
        stop-color="#ea433a"
      />
      <stop
        offset="1"
        stop-color="#b327bf"
      />
    </linearGradient>
    <path
      d="M114.7 101.3c17.2-2.5 29-18.5 26.5-35.7s-18.5-29-35.7-26.5S76.4 57.6 79 74.8v.3c2.7 17.1 18.6 28.8 35.7 26.2zm-1.6-10c-11.6 2-22.7-5.8-24.7-17.4-2-11.6 5.8-22.7 17.4-24.7s22.7 5.8 24.7 17.4c0 .1 0 .2.1.3 1.9 11.6-5.9 22.5-17.5 24.4z"
      fill="url(#circle)"
    />
    <linearGradient
      id="square"
      gradientUnits="userSpaceOnUse"
      x1="44.783"
      y1="118.039"
      x2="19.355"
      y2="48.165"
      gradientTransform="matrix(1 0 0 -1 0 115.192)"
    >
      <stop
        offset="0"
        stop-color="#cc9300"
      />
      <stop
        offset=".26"
        stop-color="#ea433a"
      />
      <stop
        offset=".47"
        stop-color="#b327bf"
      />
      <stop
        offset=".76"
        stop-color="#66f"
      />
      <stop
        offset="1"
        stop-color="#00bf9a"
      />
    </linearGradient>
    <path
      d="M13.2 64.1l46.6-7.4c2.7-.4 4.6-3 4.2-5.7v-.1L56.7 4.3c-.4-2.7-3-4.6-5.7-4.2h-.1L4.2 7.5c-2.7.4-4.6 3-4.2 5.7v.1L7.4 60c.5 2.7 3 4.5 5.8 4.1zm3.4-10.7l-5.9-36.8 36.7-5.8 5.8 36.8-36.6 5.8z"
      fill="url(#square)"
    />
    <linearGradient
      id="triangle"
      gradientUnits="userSpaceOnUse"
      x1="27.361"
      y1="-31.323"
      x2="72.461"
      y2="15.378"
      gradientTransform="matrix(1 0 0 -1 0 115.192)"
    >
      <stop
        offset="0"
        stop-color="#b327bf"
      />
      <stop
        offset=".47"
        stop-color="#66f"
      />
      <stop
        offset="1"
        stop-color="#00c09a"
      />
    </linearGradient>
    <path
      d="M42.8 81.1l-20.2 56.6c-1 2.6.4 5.5 2.9 6.4.8.3 1.7.4 2.6.2l56.9-9c2.7-.4 4.6-2.9 4.2-5.7-.1-.9-.5-1.7-1-2.4L51.5 79.7c-1.7-2.2-4.8-2.7-7-1-.8.6-1.4 1.4-1.7 2.4zm6.4 12.1l25.9 33.6-40.2 6.4 14.3-40z"
      fill="url(#triangle)"
    />
  </svg>
</template>
